import fetch from "isomorphic-fetch"
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client"

export const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.GATSBY_WORDPRESS_URL,
    fetch,
    fetchOptions: {
      "Access-Control-Allow-Origin": "*",
    }
  }),
  cache: new InMemoryCache(),
})
