import React, { createContext, useState, useLayoutEffect } from "react"

export const LangContext = createContext()

export const LangProvider = ({ children }) => {
    const [lang, setLang] = useState("EN")

    useLayoutEffect(() => {
        const langSession = window.localStorage.getItem("duti-lang")
        setLang(langSession ? langSession : "EN")
    }, [])

    return (
        <LangContext.Provider value={{ lang, setLang }}>
            {children}
        </LangContext.Provider>
    )
}
