import React, { createContext, useLayoutEffect, useState } from 'react'

export const StockContext = createContext()

export function StockProvider({ children }) {
  const [stock, setStock] = useState({
    date: "",
    open: "",
    high: "",
    low: "",
    close: "",
    adjclose: "",
  })

  useLayoutEffect(() => {
    fetch(
      'https://yahoo-finance15.p.rapidapi.com/api/yahoo/hi/history/DUTI.JK/1d',
      {
        method: "GET",
        headers: {
          "x-rapidapi-host": "yahoo-finance15.p.rapidapi.com",
          "x-rapidapi-key": process.env.GATSBY_YAHOO_API_KEY,
          useQueryString: true,
        },
      }
    )
      .then(duti => duti.json())
      .then(duti => {
        let lastItem = Object.values(duti.body)[Object.keys(duti.body).length - 1]
        setStock(lastItem)
      })
      .catch(err => console.log(err.message))
  }, [])

  return (
    <StockContext.Provider value={{ stock, setStock }}>
      {children}
    </StockContext.Provider>
  )
}
